<template>
  <div class="login">
    <div v-if="loadedData" class="card">
      <div class="card-body">
        <h4>
          <span
              class="fas fa-user"
          ></span>
          Most played games
        </h4>
        <br>
        <ul class="list-group">
          <li
            v-for="player in players"
            :key="`${player.platform}-${player.region}-${player.playerName}`"
            class="list-group-item d-flex justify-content-between"
          >
            <span
              :class="getPlatformFontAwesomeIcon(player.platform)"
            ></span>
            <router-link
              :to="{ path: generateProfileUrl(player) }"
              :onclick="
                `ga('send',
                    'event',
                    'Profile',
                    'TopPlayed', '${player.playerName}-${player.platform}-${player.region}')`
              "
            >
              <span>{{ player.playerName }}</span>
            </router-link>
            <span
              class="badge badge-secondary badge-pill"
            >
              {{ player.aggregations.roundsPlayed }}
            </span>
          </li>
        </ul>
      </div>
    </div>
    <div v-if="!loadedData" class="notLoadedPlayers">
      <div class="row">
        <div class="col-12">
          <div class="card">
            <div class="card-body">
              <div class="text-center">
                <span class="fas fa-spinner fa-spin"></span>
                <p>
                  Loading player list
                </p>
                <p
                  v-if="errorMSG"
                  :class="errorMSG ? 'animated flash' : ''"
                  class="p-3 mb-2 bg-danger text-white"
                >
                  {{ errorMSG }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
const axios = require('axios');

export default {
  name: 'TopPlayedGames',
  data() {
    return {
      players: [],
      loadedData: false,
      errorMSG: '',
      platforms: {
        pc: 'fas fa-desktop',
        xbox: 'fab fa-xbox',
        psn: 'fab fa-xbox',
        stadia: 'fab fa-xbox',
      },
    };
  },
  mounted() {
    axios.get(`${this.$root.$data.api}/player/top`)
      .then((result) => {
        if (result.data.success === true) {
          this.processSuccess(result.data.data);
        }
      })
      .catch((error) => {
        let errorData;
        if (error.response === undefined) {
          errorData = {
            error: 'Unknown error 😢. Please, try again later!',
          };
        } else {
          errorData = error.response.data;
        }

        this.processError(errorData);
      });
  },
  methods: {
    processSuccess(data) {
      this.players = data.players;
      this.loadedData = true;
    },
    processError(error) {
      this.errorMSG = error.error;
    },
    generateProfileUrl(player) {
      return `profile/${player.platform}/${player.region}/${player.playerName}`;
    },
    getPlatformFontAwesomeIcon(platform) {
      return this.platforms[platform];
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h1, h2 {
  font-weight: normal;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
</style>
