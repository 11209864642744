<template>
  <div class="footerModals">
    <div
    id="aboutMe"
    class="modal fade"
    tabindex="-1"
    role="dialog"
    aria-labelledby="aboutMeLink"
    aria-hidden="true"
    >
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">About Me</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <p>Obviously, I'm a Web developer :)<br>
            This WebApp has been developed in my free time, so it might have several bugs.<br>
            I apologize in advance 🙄
            </p>
            <p>
            I really hope you like it and used it daily
            in order to improve your already awesome PUBG skills 😱
            </p>
            <p>
              Other projects: <a title="Valdebebas APP" href="https://valdebebas.app?utm_source=pubgprofile-xyz&utm_medium=referral&utm_campaign=about-me">https://valdebebas.app</a>
            </p>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-primary" data-dismiss="modal">Close</button>
          </div>
        </div>
      </div>
    </div>


    <div
    id="privacyPolicy"
    class="modal fade"
    tabindex="-1"
    role="dialog"
    aria-labelledby="privacyPolicyLink"
    aria-hidden="true"
    >
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Privacy Policy</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <p>
              This website stores only the essential information.
            </p>
            <p>
              Player nicks are the only personal information which is saved in this system.
              This data won't be sent to any other company or website.
              This website will store some cookies in your computer in order to improve your
              experience while using it!.
              <a
                id="cookiesPolicyLink"
                href="#"
                data-toggle="modal"
                data-target="#cookiesPolicy">
                Cookies Policy
              </a>
            </p>
            <p>
              In order to improve this website (analytical usage), we make use of Google Analytics,
              which stores
              information such us location and device you are currently using It will also
              store cookies in order to improve their metrics. If you want more info, visit
              the official Google Analytics website.
            </p>
            <p>
              This website is not currently endorsed by Bluehole or any other company, and doesn't
              not reflect their opinion.
            </p>
            <p>
              This document contains the essential information, if you want more info,
              <a
                id="contactLink"
                href="#"
                data-toggle="modal"
                data-target="#contact">
                contact me!
              </a>
            </p>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-primary" data-dismiss="modal">Close</button>
          </div>
        </div>
      </div>
    </div>


    <div
    id="contact"
    class="modal fade"
    tabindex="-1"
    role="dialog"
    aria-labelledby="contactLink"
    aria-hidden="true"
    >
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Contact</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <p>
              You can contact me via:
              <ul>
                <li><span class="fab fa-twitter"></span> @pablo_0910</li>
                <li><span class="fab fa-discord"></span> @pablo0910</li>
              </ul>
            </p>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-primary" data-dismiss="modal">Close</button>
          </div>
        </div>
      </div>
    </div>


    <div
    id="cookiesPolicy"
    class="modal fade"
    tabindex="-1"
    role="dialog"
    aria-labelledby="cookiesPolicyLink"
    aria-hidden="true"
    >
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Contact</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <p>
              To make this site work properly, we sometimes place small data files called cookies
              on your device (like every other website).
            </p>
            <h6>What's a cookie?</h6>
            <p>
              A cookie is a small text file that a website saves on your computer or mobile device
              when you visit the site. It enables the website to remember your actions and
              preferences
              (such as login, language, font size and other display preferences) over a period of
              time, so you don’t have to keep re-entering them whenever you come back to the site
              or browse from one page to another.
            </p>
            <p>
              We use cookies for:
              <ul>
                <li>Analytics (third party)</li>
                <li>Remember your credentials</li>
              </ul>
            </p>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-primary" data-dismiss="modal">Close</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'FooterModals',
};
</script>
