<template>
  <div class="login">
    <div class="row">
      <div class="col-md-6">
        <div class="card">
          <div class="card-body">
            <h4>Login with your PUBG username</h4>
            <form v-on:submit="submit($event)" method="GET">
              <div class="form-group">
                <label
                  for="userName">
                  PUBG Nick
                </label>
                <input
                  v-model="userName"
                  type="text"
                  class="form-control"
                  id="userName"
                  aria-describedby="userNameHelp"
                  placeholder="shroud">
                <small
                  id="userNameHelp"
                  class="form-text text-muted">
                  Your name must match PUBG name (including lower and upper case).
                </small>
              </div>
              <div class="form-group">
                <label
                  for="platform">
                  Platform
                </label>
                <select
                  v-model="platform"
                  id="platform"
                  class="custom-select"
                >
                  <option
                    value="empty"
                    selected="selected"
                    disabled="disabled"
                  >
                    Select platform...
                  </option>
                  <option
                    v-for="platform in Object.keys(shards)"
                    :key="platform"
                    :value="platform">
                      {{ platform }}
                  </option>
                </select>
              </div>
              <div class="form-group">
                <label
                  for="region">
                  Region
                </label>
                <select
                  v-if="platform !== 'empty'"
                  v-model="region"
                  id="region"
                  class="custom-select"
                >
                  <option
                    value="empty"
                    v-if="platform !== 'empty'"
                    selected="selected"
                    disabled="disabled"
                  >
                    Select region...
                  </option>
                  <option
                    v-for="region in shards[platform]"
                    :key="region"
                    :value="region">
                      {{ region }}
                  </option>
                </select>
                <select
                  v-if="platform === 'empty'"
                  v-model="region"
                  id="region"
                  class="custom-select"
                >
                  <option
                    value="empty"
                    selected="selected"
                    disabled="disabled"
                  >
                    Select platform first...
                  </option>
                </select>
              </div>
              <button type="submit" class="btn btn-primary">See my stats</button>
              <br v-if="errorMSG">
              <br v-if="errorMSG">
              <p
                v-if="errorMSG"
                :class="errorMSG ? 'animated flash' : ''"
                class="p-3 mb-2 bg-danger text-white"
              >
                {{ errorMSG }}
              </p>
            </form>
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <TopPlayedGames />
      </div>
    </div>
    <div class="row">
      <div class="col-md-6">
        <div class="mt-4">
          <!-- HomeAdsVerticalBanner  -->
          <ins class="adsbygoogle"
              style="display:block"
              :data-ad-client="`ca-${homeAds.homeBannerVertical.publisherId}`"
              :data-ad-slot="homeAds.homeBannerVertical.slot"
              data-ad-format="auto"
              data-full-width-responsive="true"></ins>
        </div>
      </div>
      <div class="col-md-6">
        <div class="card mt-4">
          <div class="card-body">
            <TournamentsList />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TopPlayedGames from '@/components/Home/TopPlayedGames';
import TournamentsList from '@/components/Home/TournamentsList';

const axios = require('axios');

export default {
  name: 'LoginPage',
  components: {
    TopPlayedGames,
    TournamentsList,
  },
  data() {
    return {
      errorMSG: '',
      userName: '',
      platform: 'empty',
      region: 'empty',
      submitButtonText: 'See my stats',
      shards: {
        xbox: [
          'as',
          'eu',
          'na',
          'oc',
          'sa',
        ],
        pc: [
          'krjp',
          'jp',
          'na',
          'eu',
          'ru',
          'oc',
          'kakao',
          'sea',
          'sa',
          'as',
        ],
        psn: [
          'as',
          'eu',
          'na',
          'oc',
        ],
        stadia: [
          'global',
        ],
      },

      homeAds: {
        homeBanner: {
          publisherId: "pub-8650061678387324",
          slot: "4375559869"
        },
        homeBannerVertical: {
          publisherId: "pub-8650061678387324",
          slot: "2543178413"
        },
      },
    };
  },
  created() {
    const playerName = this.getCookie('playerName');
    const platform = this.getCookie('platform');
    const region = this.getCookie('region');

    // Set field and refresh cookie for another 7 days
    if (playerName !== null) {
      this.userName = playerName;
      this.setCookie('playerName', this.userName, 7);
    }
    if (platform !== null) {
      this.platform = platform;
      this.setCookie('platform', this.platform, 7);
    }
    if (region !== null) {
      this.region = region;
      this.setCookie('region', this.region, 7);
    }
  },
  mounted() {
    document.title = 'PUBG Player Profile';
    document.querySelector('meta[name="description"]')
      // eslint-disable-next-line
      .setAttribute('content', `Check your profile stats, have a look at all the events that took place last time you played and connect with other PUBG players.`);

    document.querySelector('meta[name="twitter:title"]')
      .setAttribute('content', 'PUBG Player Profile');
    document.querySelector('meta[name="twitter:description"]')
      // eslint-disable-next-line
      .setAttribute('content', 'Check your profile stats, have a look at all the events that took place last time you played and connect with other PUBG players.');

    document.querySelector('meta[property="og:title"]')
      .setAttribute('content', 'PUBG Player Profile');
    document.querySelector('meta[property="og:description"]')
      // eslint-disable-next-line
      .setAttribute('content', 'Check your profile stats, have a look at all the events that took place last time you played and connect with other PUBG players.');

    setTimeout(() => {
      (adsbygoogle = window.adsbygoogle || []).push({});
    }, 200);
  },
  methods: {
    submit(event) {
      event.preventDefault();

      this.setSubmitButtonLoading();
      axios.get(`${this.$root.$data.api}/player/profile/${this.platform}/${this.region}/${this.userName}`)
        .then((result) => {
          if (result.data.success === true) {
            this.processSuccess(result.data.data);
          }
          const button = document.querySelector('button');
          button.innerHTML += this.submitButtonText;
          button.disabled = false;
        })
        .catch((error) => {
          let errorData;
          if (error.response === undefined) {
            errorData = {
              error: 'Unknown error 😢. Please, try again later!',
            };
          } else {
            errorData = error.response.data;
          }

          this.processError(errorData);
          const button = document.querySelector('button');
          button.innerHTML = this.submitButtonText;
          button.disabled = false;
        });
      // this.$router.push(`map/${this.platform}/${this.region}/${this.userName}`);
    },
    processSuccess(data) {
      this.$root.$data.globalStats = data.globalStats[0];
      this.$root.$data.seasonStats = data.seasonStats;
      this.$root.$data.matchesInfo = data.matchesInfo;
      this.$root.$data.player = data.player;

      this.$root.$data.userName = this.userName;
      this.$root.$data.platform = this.platform;
      this.$root.$data.region = this.region;

      this.setCookie('playerName', this.userName, 7);
      this.setCookie('platform', this.platform, 7);
      this.setCookie('region', this.region, 7);

      ga('send', 'event', 'Player', 'login', `${this.userName}-${this.platform}-${this.region}`); // eslint-disable-line

      this.$router.push(`profile/${this.platform}/${this.region}/${this.userName}`);
    },
    processError(error) {
      this.errorMSG = error.error;
      ga('send', 'event', 'Player', 'login', 'failure'); // eslint-disable-line
    },
    setSubmitButtonLoading() {
      const button = document.querySelector('button');
      button.innerHTML += ' <span class="fas fa-circle-notch fa-spin"></span>';
      button.disabled = true;
    },
    setCookie(name, value, days) {
      const d = new Date();
      d.setTime(d.getTime() + (24 * 60 * 60 * 1000 * days));
      const expires = `; expires=${d.toGMTString()}`;
      document.cookie = `${name}=${value}${expires}; path=/`;
    },
    getCookie(name) {
      const v = document.cookie.match(`(^|;) ?${name}=([^;]*)(;|$)`);
      return v ? v[2] : null;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h1, h2 {
  font-weight: normal;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
