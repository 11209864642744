export default {
  AIPawn_Base_Female_C: "AI Player",
  AIPawn_Base_Male_C: "AI Player",
  AirBoat_V2_C: "Airboat",
  AquaRail_A_01_C: "Aquarail",
  AquaRail_A_02_C: "Aquarail",
  AquaRail_A_03_C: "Aquarail",
  BP_ATV_C: "Quad",
  BP_BRDM_C: "BRDM-2",
  BP_Bicycle_C: "Mountain Bike",
  BP_CoupeRB_C: "Coupe RB",
  BP_DO_Circle_Train_Merged_C: "Train",
  BP_DO_Line_Train_Dino_Merged_C: "Train",
  BP_DO_Line_Train_Merged_C: "Train",
  BP_Dirtbike_C: "Dirt Bike",
  BP_DronePackage_Projectile_C: "Drone",
  BP_Eragel_CargoShip01_C: "Ferry Damage",
  BP_FakeLootProj_AmmoBox_C: "Loot Truck",
  BP_FakeLootProj_MilitaryCrate_C: "Loot Truck",
  BP_FireEffectController_C: "Molotov Fire",
  BP_FireEffectController_JerryCan_C: "Jerrycan Fire",
  BP_Helicopter_C: "Pillar Scout Helicopter",
  BP_IncendiaryDebuff_C: "Burn",
  BP_JerryCanFireDebuff_C: "Burn",
  BP_JerryCan_FuelPuddle_C: "Burn",
  BP_KillTruck_C: "Kill Truck",
  BP_LootTruck_C: "Loot Truck",
  BP_M_Rony_A_01_C: "Rony",
  BP_M_Rony_A_02_C: "Rony",
  BP_M_Rony_A_03_C: "Rony",
  BP_Mirado_A_02_C: "Mirado",
  BP_Mirado_A_03_Esports_C: "Mirado",
  BP_Mirado_Open_03_C: "Mirado (open top)",
  BP_Mirado_Open_04_C: "Mirado (open top)",
  BP_Mirado_Open_05_C: "Mirado (open top)",
  BP_MolotovFireDebuff_C: "Molotov Fire Damage",
  BP_Motorbike_04_C: "Motorcycle",
  BP_Motorbike_04_Desert_C: "Motorcycle",
  BP_Motorbike_04_SideCar_C: "Motorcycle (w/ Sidecar)",
  BP_Motorbike_04_SideCar_Desert_C: "Motorcycle (w/ Sidecar)",
  BP_Motorbike_Solitario_C: "Motorcycle",
  BP_Motorglider_C: "Motor Glider",
  BP_Motorglider_Green_C: "Motor Glider",
  BP_Niva_01_C: "Zima",
  BP_Niva_02_C: "Zima",
  BP_Niva_03_C: "Zima",
  BP_Niva_04_C: "Zima",
  BP_Niva_05_C: "Zima",
  BP_Niva_06_C: "Zima",
  BP_Niva_07_C: "Zima",
  BP_PickupTruck_A_01_C: "Pickup Truck (closed top)",
  BP_PickupTruck_A_02_C: "Pickup Truck (closed top)",
  BP_PickupTruck_A_03_C: "Pickup Truck (closed top)",
  BP_PickupTruck_A_04_C: "Pickup Truck (closed top)",
  BP_PickupTruck_A_05_C: "Pickup Truck (closed top)",
  BP_PickupTruck_A_esports_C: "Pickup Truck (closed top)",
  BP_PickupTruck_B_01_C: "Pickup Truck (open top)",
  BP_PickupTruck_B_02_C: "Pickup Truck (open top)",
  BP_PickupTruck_B_03_C: "Pickup Truck (open top)",
  BP_PickupTruck_B_04_C: "Pickup Truck (open top)",
  BP_PickupTruck_B_05_C: "Pickup Truck (open top)",
  BP_PonyCoupe_C: "Pony Coupe",
  BP_Porter_C: "Porter",
  BP_Scooter_01_A_C: "Scooter",
  BP_Scooter_02_A_C: "Scooter",
  BP_Scooter_03_A_C: "Scooter",
  BP_Scooter_04_A_C: "Scooter",
  BP_Snowbike_01_C: "Snowbike",
  BP_Snowbike_02_C: "Snowbike",
  BP_Snowmobile_01_C: "Snowmobile",
  BP_Snowmobile_02_C: "Snowmobile",
  BP_Snowmobile_03_C: "Snowmobile",
  BP_Spiketrap_C: "Spike Trap",
  BP_TslGasPump_C: "Gas Pump",
  BP_TukTukTuk_A_01_C: "Tukshai",
  BP_TukTukTuk_A_02_C: "Tukshai",
  BP_TukTukTuk_A_03_C: "Tukshai",
  BP_Van_A_01_C: "Van",
  BP_Van_A_02_C: "Van",
  BP_Van_A_03_C: "Van",
  BattleRoyaleModeController_Chimera_C: "Bluezone",
  BattleRoyaleModeController_Def_C: "Bluezone",
  BattleRoyaleModeController_Desert_C: "Bluezone",
  BattleRoyaleModeController_DihorOtok_C: "Bluezone",
  BattleRoyaleModeController_Heaven_C: "Bluezone",
  BattleRoyaleModeController_Kiki_C: "Bluezone",
  BattleRoyaleModeController_Savage_C: "Bluezone",
  BattleRoyaleModeController_Summerland_C: "Bluezone",
  BattleRoyaleModeController_Tiger_C: "Bluezone",
  BlackZoneController_Def_C: "Blackzone",
  Bluezonebomb_EffectActor_C: "Bluezone Grenade",
  Boat_PG117_C: "PG-117",
  Buff_DecreaseBreathInApnea_C: "Drowning",
  Buggy_A_01_C: "Buggy",
  Buggy_A_02_C: "Buggy",
  Buggy_A_03_C: "Buggy",
  Buggy_A_04_C: "Buggy",
  Buggy_A_05_C: "Buggy",
  Buggy_A_06_C: "Buggy",
  Carepackage_Container_C: "Care Package",
  Dacia_A_01_v2_C: "Dacia",
  Dacia_A_01_v2_snow_C: "Dacia",
  Dacia_A_02_v2_C: "Dacia",
  Dacia_A_03_v2_C: "Dacia",
  Dacia_A_03_v2_Esports_C: "Dacia",
  Dacia_A_04_v2_C: "Dacia",
  DroppedItemGroup: "Object Fragments",
  EmergencyAircraft_Tiger_C: "Emergency Aircraft",
  Jerrycan: "Jerrycan",
  JerrycanFire: "Jerrycan Fire",
  Lava: "Lava",
  Mortar_Projectile_C: "Mortar Projectile",
  None: "None",
  PG117_A_01_C: "PG-117",
  PanzerFaust100M_Projectile_C: "Panzerfaust Projectile",
  PlayerFemale_A_C: "Player",
  PlayerMale_A_C: "Player",
  ProjC4_C: "C4",
  ProjGrenade_C: "Frag Grenade",
  ProjIncendiary_C: "Incendiary Projectile",
  ProjMolotov_C: "Molotov Cocktail",
  ProjMolotov_DamageField_Direct_C: "Molotov Cocktail Fire Field",
  ProjStickyGrenade_C: "Sticky Bomb",
  RacingDestructiblePropaneTankActor_C: "Propane Tank",
  RacingModeContorller_Desert_C: "Bluezone",
  RedZoneBomb_C: "Redzone",
  RedZoneBombingField: "Redzone",
  RedZoneBombingField_Def_C: "Redzone",
  TslDestructibleSurfaceManager: "Destructible Surface",
  TslPainCausingVolume: "Lava",
  Uaz_A_01_C: "UAZ (open top)",
  Uaz_Armored_C: "UAZ (armored)",
  Uaz_B_01_C: "UAZ (soft top)",
  Uaz_B_01_esports_C: "UAZ (soft top)",
  Uaz_C_01_C: "UAZ (hard top)",
  UltAIPawn_Base_Female_C: "Player",
  UltAIPawn_Base_Male_C: "Player",
  WeapACE32_C: "ACE32",
  WeapAK47_C: "AKM",
  WeapAUG_C: "AUG A3",
  WeapAWM_C: "AWM",
  WeapBerreta686_C: "S686",
  WeapBerylM762_C: "Beryl",
  WeapBizonPP19_C: "Bizon",
  WeapCowbarProjectile_C: "Crowbar Projectile",
  WeapCowbar_C: "Crowbar",
  WeapCrossbow_1_C: "Crossbow",
  WeapDP12_C: "DBS",
  WeapDP28_C: "DP-28",
  WeapDesertEagle_C: "Deagle",
  WeapDuncansHK416_C: "M416",
  WeapFNFal_C: "SLR",
  WeapG18_C: "P18C",
  WeapG36C_C: "G36C",
  WeapGroza_C: "Groza",
  WeapHK416_C: "M416",
  WeapJuliesKar98k_C: "Kar98k",
  WeapK2_C: "K2",
  WeapKar98k_C: "Kar98k",
  WeapL6_C: "Lynx AMR",
  WeapLunchmeatsAK47_C: "AKM",
  WeapM16A4_C: "M16A4",
  WeapM1911_C: "P1911",
  WeapM249_C: "M249",
  WeapM24_C: "M24",
  WeapM9_C: "P92",
  WeapMG3_C: "MG3",
  WeapMP5K_C: "MP5K",
  WeapMacheteProjectile_C: "Machete Projectile",
  WeapMachete_C: "Machete",
  WeapMadsQBU88_C: "QBU88",
  WeapMini14_C: "Mini 14",
  WeapMk12_C: "Mk12",
  WeapMk14_C: "Mk14 EBR",
  WeapMk47Mutant_C: "Mk47 Mutant",
  WeapMosinNagant_C: "Mosin-Nagant",
  WeapNagantM1895_C: "R1895",
  WeapOriginS12_C: "O12",
  WeapP90_C: "P90",
  WeapPanProjectile_C: "Pan Projectile",
  WeapPan_C: "Pan",
  WeapPanzerFaust100M1_C: "Panzerfaust",
  WeapQBU88_C: "QBU88",
  WeapQBZ95_C: "QBZ95",
  WeapRhino_C: "R45",
  "WeapSCAR-L_C": "SCAR-L",
  WeapSKS_C: "SKS",
  WeapSaiga12_C: "S12K",
  WeapSawnoff_C: "Sawed-off",
  WeapSickleProjectile_C: "Sickle Projectile",
  WeapSickle_C: "Sickle",
  WeapThompson_C: "Tommy Gun",
  WeapTurret_KillTruck_Main_C: "Kill Truck Turret",
  WeapUMP_C: "UMP9",
  WeapUZI_C: "Micro Uzi",
  WeapVSS_C: "VSS",
  WeapVector_C: "Vector",
  WeapWin94_C: "Win94",
  WeapWinchester_C: "S1897",
  Weapvz61Skorpion_C: "Skorpion"
}
