<template>
  <div class="pubg-map">
    <div v-if="matchLoaded" class="loadedMap">
      <!--<div class="jumbotron">
        <h2 class="display-4">
          <strong>{{ $root.$data.userName }}</strong>
          stats on <strong>{{ mapName }}</strong>
        </h2>
        <p class="lead">
          Showing {{ $root.$data.userName }}
          last game stats on {{ mapName }}
        </p>
        <p>
          <small>(Debug: {{ matchId }})</small>
        </p>
      </div>-->
      <div class="row">
        <div
          v-for="participantStats in matchData.stats.roster.participants"
          :class="statsSize"
          :key="participantStats.name"
        >
          <PUBGStats
            :stats="participantStats"
            :playerName="matchData.stats.player.name"
            :playerId="$route.params.playerId !== undefined ? $route.params.playerId : undefined"
          ></PUBGStats>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <div class="img-container pubg-img-container">
            <img
              v-on:load="drawMap()"
              v-on:resize="drawMap()"
              class="img-fluid"
              :title="mapName"
              :alt="mapName"
              :src="getMapUrl(mapKey)"
            >
            <div class="line-container">
              <svg class="line-canvas"></svg>
            </div>
          </div>
        </div>
      </div>
      <div
          v-if="matchData.stats.roster.won !== 'true'"
          class="row winnerRoster"
      >
        <div
          v-for="participantStats in matchData.stats.winnerRoster.participants"
          :class="statsSize"
          :key="participantStats.name"
        >
          <PUBGStats
            :stats="participantStats"
            :playerId="$route.params.playerId !== undefined ? $route.params.playerId : undefined"
          ></PUBGStats>
        </div>
      </div>
      <div class="playerList">
        <PlayerList
          :finalParticipantList="gameData.matchEnd.characters"
          :playerRanking="matchData.stats.player.winPlace"
          :platform="$root.$data.platform"
          :region="$root.$data.region"
          :matchId="matchId"
          :playerId="$route.params.playerId !== undefined ? $route.params.playerId : undefined"
        ></PlayerList>
      </div>
    </div>
    <div v-if="!matchLoaded" class="notLoadedMap">
      <div class="row">
        <div class="col-12">
          <div class="card">
            <div class="card-body">
              <div class="text-center">
                <span class="fas fa-spinner fa-spin"></span>
                <p>
                  Loading newest <strong>{{ $root.$data.userName }}</strong> match data.
                  This operation may take a while. Please be patient 😀!
                </p>
                <p
                  v-if="errorMSG"
                  :class="errorMSG ? 'animated flash' : ''"
                  class="p-3 mb-2 bg-danger text-white"
                >
                  {{ errorMSG }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="modal-container">
      <PlayerKill
        :playerKill="playerKillModal.playerKill"
        :location="playerKillModal.location"
        :playerName="$root.$data.userName"
        :matchId="matchId"
        :playerId="$route.params.playerId !== undefined ? $route.params.playerId : undefined"
      />
      <button
        id="displayPlayerKill"
        type="button"
        class="d-none"
        data-toggle="modal"
        data-target="#playerKillModal"
      >
      </button>
      <PlayerKillV2
        :playerKillV2="playerKillModal.playerKillV2"
        :location="playerKillModal.location"
        :playerName="$root.$data.userName"
        :matchId="matchId"
        :playerId="$route.params.playerId !== undefined ? $route.params.playerId : undefined"
      />
      <button
        id="displayPlayerKillV2"
        type="button"
        class="d-none"
        data-toggle="modal"
        data-target="#playerKillV2Modal"
      >
      </button>
      <GameLog
        :gameLog="gameLogModal"
        :playerName="$root.$data.userName"
        :playerId="$route.params.playerId !== undefined ? $route.params.playerId : undefined"
      />
    </div>
  </div>
</template>

<script>
import PUBGStats from '@/components/PUBGMap/PUBGStats';
import PlayerList from '@/components/PUBGMap/PlayerList';
import PlayerKill from '@/components/PUBGMap/MoreInfo/PlayerKill';
import PlayerKillV2 from '@/components/PUBGMap/MoreInfo/PlayerKillV2';
import GameLog from '@/components/PUBGMap/MoreInfo/GameLog';

import erangelIMG from '../../assets/img/erangel.jpg';
import miramarIMG from '../../assets/img/miramar.jpg';
import sanhokIMG from '../../assets/img/sanhok.jpg';
import karakinIMG from '../../assets/img/karakin.png';
import campJackalIMG from '../../assets/img/campjackal.png';
import vikendiIMG from '../../assets/img/vikendi.jpg';
import paramoIMG from '../../assets/img/paramo.jpg';
import havenIMG from '../../assets/img/haven.png';
import taegoIMG from '../../assets/img/taego.jpg';
import destonIMG from '../../assets/img/deston.jpg';
import skullIMG from '../../assets/img/skull-icon.png';
import boardwalkIMG from '../../assets/img/boardwalk.jpg';
import rondoIMG from '../../assets/img/rondo.jpg';

const axios = require('axios');

export default {
  name: 'PUBGMap',
  components: {
    PUBGStats,
    PlayerList,
    PlayerKill,
    PlayerKillV2,
    GameLog,
  },
  data() {
    return {
      matchLoaded: false,
      errorMSG: '',
      mapKey: '',
      mapName: '',
      matchId: '',
      gameData: null,
      matchData: null,
      statsSize: 'col-12',
      playerKillModal: {
        playerKill: null,
        playerKillV2: null,
        location: null,
      },
      gameLogModal: {
        playerAttack: [],
        playerTakeDamage: [],
      },
    };
  },
  methods: {
    getMapName(mapKey) {
      return mapKey.charAt(0).toUpperCase()
          + mapKey.slice(1);
    },
    getMapUrl(mapKey) {
      const mapKeys = {
        erangel: erangelIMG,
        "erangel v2": erangelIMG,
        miramar: miramarIMG,
        sanhok: sanhokIMG,
        "camp jackal": campJackalIMG,
        vikendi: vikendiIMG,
        karakin: karakinIMG,
        paramo: paramoIMG,
        haven: havenIMG,
        taego: taegoIMG,
        deston: destonIMG,
        boardwalk: boardwalkIMG,
        rondo: rondoIMG,
      };
      return mapKeys[mapKey];
    },
    processSuccess(data) {
      this.matchId = data.telemetry.matchId;
      this.mapKey = data.telemetry.mapKey;
      this.mapName = this.getMapName(this.mapKey);
      this.gameData = data.telemetry;
      this.matchData = data.match;

      this.gameLogModal = {
        playerAttack: data.telemetry.playerAttack,
        playerTakeDamage: data.telemetry.playerTakeDamage,
      };

      /* switch (this.matchData.stats.roster.participants.length) {
        case 1:
          this.statsSize = 'col';
          break;
        case 2:
          this.statsSize = 'col-md-6';
          break;
        case 3:
          this.statsSize = 'col-xl-4 col-md-6';
          break;
        default:
          this.statsSize = 'col-xl-3 col-md-6';
          break;
      } */

      this.matchLoaded = true;

      this.$nextTick(() => {
        this.matchId = data.telemetry.matchId;
      });
    },
    processError(error) {
      this.errorMSG = error.error;
    },
    drawMap() {
      let positionFactor;
      if (this.mapKey === 'karakin') {
        positionFactor = 204000;
      } else if (this.mapKey === 'sanhok') {
        positionFactor = 408000;
      } else if (this.mapKey === 'camp jackal') {
        positionFactor = 204000;
      } else if (this.mapKey === 'boardwalk') {
        positionFactor = 204000;
      } else if (this.mapKey === 'paramo') {
        positionFactor = 306000;
      } else if (this.mapKey === 'haven') {
        positionFactor = 102000;
      } else {
        positionFactor = 816000;
      }

      const image = this.$el.querySelector('.pubg-img-container').querySelectorAll('img')[0];
      const imageParent = image.parentElement;
      const lineContainer = imageParent.querySelectorAll('.line-container')[0];
      const imgWidthFactor = image.width / positionFactor;
      const imgHeightFactor = image.height / positionFactor;

      const elements = imageParent.querySelectorAll('.removable,polyline');
      if (elements.length > 0) {
        [].forEach.call(elements, (element) => {
          element.parentNode.removeChild(element);
        });
      }

      const svg = imageParent.querySelectorAll('svg')[0];
      svg.style.position = 'absolute';
      svg.style.left = 0;
      svg.style.top = 0;
      svg.parentElement.style.width = `${image.width}px`;
      svg.parentElement.style.height = `${image.height}px`;
      svg.parentElement.style.top = 0;
      svg.parentElement.style.left = 0;

      let polyline;
      this.gameData.playerPosition.forEach((player, index) => {
        if (player.elapsedTime === 0) {
          return;
        }

        const location = player.character.location;

        // Plane
        if (location.z === 150088) {
          if (typeof polyline !== 'undefined') {
            svg.appendChild(polyline);
            polyline = undefined;
          }

          const node = document.createElement('span');
          node.style.color = 'white';
          node.className = `${node.className} fas fa-plane removable`;
          node.style.position = 'absolute';
          node.style.display = 'inline-block';
          node.style['z-index'] = 1000;
          node.style.left = `${Math.round(location.x * imgWidthFactor) - 8}px`;
          node.style.top = `${Math.round(location.y * imgHeightFactor) - 8}px`;

          if (location.x > 0 && location.y > 0) {
            imageParent.appendChild(node);
          }
        } else {
          if (typeof polyline === 'undefined') {
            polyline = document.createElementNS('http://www.w3.org/2000/svg', 'polyline');
            polyline.style.fill = 'none';
            polyline.style.stroke = '#FF7F50';
            polyline.style['stroke-width'] = 3;
          }

          setTimeout(() => {
            const node = document.createElement('span');
            node.style.color = '#7FFF00';
            node.className = `${node.className} fas fa-circle removable`;
            node.style.position = 'absolute';
            node.style['z-index'] = 1000;
            node.style.left = `${Math.round(location.x * imgWidthFactor) - 5}px`;
            node.style.top = `${Math.round(location.y * imgHeightFactor) - 5}px`;
            lineContainer.appendChild(node);
          }, index * 40);

          const point = svg.createSVGPoint();
          point.x = Math.round(location.x * imgWidthFactor);
          point.y = Math.round(location.y * imgHeightFactor);
          polyline.points.appendItem(point);
        }
      });

      this.gameData.playerKill.forEach((playerKill) => {
        if (playerKill.victim.name === this.$root.$data.userName) {
          return;
        }

        const divNode = document.createElement('div');
        divNode.className = `${divNode.className} killInfo removable`;
        divNode.style.cursor = 'pointer';
        divNode.style.position = 'absolute';
        divNode.style['z-index'] = 1000;
        divNode.style.left = `${Math.round(playerKill.victim.location.x * imgWidthFactor)}px`;
        divNode.style.top = `${Math.round(playerKill.victim.location.y * imgHeightFactor)}px`;

        const node = document.createElement('img');
        node.title = 'Player Killed';
        node.alt = 'Player Killed';
        node.src = skullIMG;
        divNode.appendChild(node);

        divNode.addEventListener('click', () => {
          this.loadKillInfo(playerKill);
        });

        imageParent.appendChild(divNode);
      });

      this.gameData.playerKillV2.forEach((playerKillV2) => {
        if (playerKillV2.victim.name === this.$root.$data.userName) {
          return;
        }

        const divNode = document.createElement('div');
        divNode.className = `${divNode.className} killInfoV2 removable`;
        divNode.style.cursor = 'pointer';
        divNode.style.position = 'absolute';
        divNode.style['z-index'] = 1000;
        divNode.style.left = `${Math.round(playerKillV2.victim.location.x * imgWidthFactor)}px`;
        divNode.style.top = `${Math.round(playerKillV2.victim.location.y * imgHeightFactor)}px`;

        if (playerKillV2.killer.name === this.$root.$data.userName) {
          const node = document.createElement('img');
          node.title = 'Player Killed';
          node.alt = 'Player Killed';
          node.src = skullIMG;
          divNode.appendChild(node);
        } else {
          const node = document.createElement('img');
          node.title = 'Player knocked down';
          node.alt = 'Player knocked down';
          node.src = skullIMG;
        }

        divNode.addEventListener('click', () => {
          this.loadKillInfoV2(playerKillV2);
        });

        imageParent.appendChild(divNode);
      });

      svg.appendChild(polyline);

      const lastLocation =
        this.gameData.playerPosition[this.gameData.playerPosition.length - 1].character.location;
      const divNode = document.createElement('div');
      divNode.className = `${divNode.className} deadInfo removable`;
      divNode.style.cursor = 'pointer';
      divNode.style.position = 'absolute';
      divNode.style['z-index'] = 1000;
      divNode.style.left = `${Math.round(lastLocation.x * imgWidthFactor)}px`;
      divNode.style.top = `${Math.round(lastLocation.y * imgHeightFactor)}px`;

      const node = document.createElement('span');
      node.style.color = 'red';
      node.className = `${node.className} fas fa-flag-checkered`;
      divNode.appendChild(node);

      imageParent.appendChild(divNode);


      // Bind events to drawn elements
      document.querySelector('.deadInfo').addEventListener('click', () => {
        this.loadDeadInfo();
      });
    },
    loadDeadInfo() {
      let playerDeadInfo = null;
      this.gameData.playerKill.forEach((playerKill) => {
        if (playerKill.victim.name === this.$root.$data.userName) {
          playerDeadInfo = playerKill;
        }
      });

      this.playerKillModal.playerKill = playerDeadInfo;
      this.playerKillModal.location =
        this.gameData.playerPosition[this.gameData.playerPosition.length - 1];

      this.showKillModal();
    },
    loadKillInfo(playerKill) {
      this.playerKillModal.playerKill = playerKill;
      this.playerKillModal.location = null;

      this.showKillModal();
    },
    showKillModal() {
      const event = document.createEvent('HTMLEvents');
      event.initEvent('click', true, false);
      document.getElementById('displayPlayerKill').dispatchEvent(event);
    },
    loadKillInfoV2(playerKillV2) {
      this.playerKillModal.playerKillV2 = playerKillV2;
      this.playerKillModal.location = null;

      this.showKillModalV2();
    },
    showKillModalV2() {
      const event = document.createEvent('HTMLEvents');
      event.initEvent('click', true, false);
      document.getElementById('displayPlayerKillV2').dispatchEvent(event);
    },
  },
  mounted() {
    this.$root.$data.platform = this.$route.params.platform;
    this.$root.$data.region = this.$route.params.region;
    this.$root.$data.userName = this.$route.params.userName;
    this.errorMSG = '';

    document.title = `${this.$root.$data.userName} .::. Map Stats - PUBG Player Profile`;
    document.querySelector('meta[name="description"]')
      // eslint-disable-next-line
      .setAttribute('content', `${this.$root.$data.userName} - ${this.$root.$data.platform.toUpperCase()} map stats, including where kills took place, path followed and enemies info among other interesting stats.`);

    document.querySelector('meta[name="twitter:title"]')
      .setAttribute('content', `${this.$root.$data.userName} .::. Map Stats - PUBG Player Profile`);
    document.querySelector('meta[name="twitter:description"]')
      // eslint-disable-next-line
      .setAttribute('content', `${this.$root.$data.userName} - ${this.$root.$data.platform.toUpperCase()} map stats, including where kills took place, path followed and enemies info among other interesting stats.`);

    document.querySelector('meta[property="og:title"]')
      .setAttribute('content', `${this.$root.$data.userName} .::. Map Stats - PUBG Player Profile`);
    document.querySelector('meta[property="og:description"]')
      // eslint-disable-next-line
      .setAttribute('content', `${this.$root.$data.userName} - ${this.$root.$data.platform.toUpperCase()} map stats, including where kills took place, path followed and enemies info among other interesting stats.`);

    let suffixURI = '';
    if (typeof this.$route.params.matchId !== 'undefined') {
      suffixURI = `find/${this.$root.$data.platform}/${this.$root.$data.region}/${this.$root.$data.userName}/${this.$route.params.matchId}`;
      if (this.$route.params.playerId !== undefined) {
        suffixURI += `/${this.$route.params.playerId}`;
      }
    } else {
      suffixURI = `last/${this.$root.$data.platform}/${this.$root.$data.region}/${this.$root.$data.userName}`;
    }

    axios.get(`${this.$root.$data.api}/game/${suffixURI}`)
      .then((result) => {
        if (result.data.success === true) {
          this.processSuccess(result.data.data);
        }
      })
      .catch((error) => {
        this.processError(error.response.data);
      });

    let resizeEventTimeOut;
    window.addEventListener('resize', () => {
      clearTimeout(resizeEventTimeOut);
      resizeEventTimeOut = setTimeout(() => this.drawMap.bind(this), 500);
    });
    /* this.$nextTick(() => {
      setTimeout(() => {
        this.processSuccess(tempJSON.data);
      }, 1000);
    }); */
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  .img-container {
    position: relative;
  }
  .line-container {
    position: absolute;
    font-size: 10px;
    opacity: 0.8;
  }
  .line-container svg {
    height: 100%;
    width: 100%;
  }
  .line-container .line-canvas {
    opacity: 0.7;
  }
  .winnerRoster {
    margin-top: 20px;
  }
  .clickable {
    cursor: pointer;
  }
</style>
