<template>
  <div class="stats-card">
    <div class="card p-3 mb-2 bg-dark text-white">
      <div class="card-body">
        <h6 class="card-title text-warning stats-card--card-title">
          <span v-if="title !== 'Headshots'" :class="icon"></span>
          <span v-else>
            <img
              title="Headshots"
              alt="Headshots"
              src="../../assets/img/icons8-sniper-64.png"
              style="width: 1.125em; height: 1.125 em;">
          </span>
          <span class="text-white">{{ title }}</span>
        </h6>
        <p class="card-text">
          <span class="stats-card--value">{{ value }}</span>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'StatsCard',
  props: [
    'icon',
    'title',
    'value',
  ],
  data() {
    return {
    };
  },
};
</script>

<style>
  .stats-card {
    margin-top: 20px;
  }
  .stats-card--value {
    font-weight: 900;
    font-size: 2em;
  }
  .stats-card--card-title {
    font-size: 1.33em;
  }
</style>
