<template>
  <div class="tournaments-list">
    <div v-if="loadedData">
      <h3>
        <span class="fas fa-trophy"></span> Tournaments List
      </h3>
      <div class="list-group">
        <router-link
          :to="{ path: generateTournamentUrl(tournament.id) }"
          v-for="tournament in tournaments"
          :key="tournament.id"
          class="list-group-item list-group-item-action"
          style="display: inline; width: auto;"
        >
          {{ tournament.id }}
        </router-link>
      </div>
    </div>
    <div v-if="!loadedData" class="notLoadedTournamentList">
      <div class="row">
        <div class="col-12">
          <div class="card">
            <div class="card-body">
              <div class="text-center">
                <span class="fas fa-spinner fa-spin"></span>
                <p>
                  Loading tournaments
                </p>
                <p
                  v-if="errorMSG"
                  :class="errorMSG ? 'animated flash' : ''"
                  class="p-3 mb-2 bg-danger text-white"
                >
                  {{ errorMSG }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
const axios = require('axios');

export default {
  name: 'TournamentsList',
  data() {
    return {
      tournaments: [],
      loadedData: false,
      errorMSG: '',
    };
  },
  mounted() {
    axios.get(`${this.$root.$data.api}/tournament`)
      .then((result) => {
        if (result.data.success === true) {
          this.processSuccess(result.data.data);
        }
      })
      .catch((error) => {
        let errorData;
        if (error.response === undefined) {
          errorData = {
            error: 'Unknown error 😢. Please, try again later!',
          };
        } else {
          errorData = error.response.data;
        }

        this.processError(errorData);
      });
  },
  methods: {
    processSuccess(data) {
      this.tournaments = data.tournaments;
      this.loadedData = true;
    },
    processError(error) {
      this.errorMSG = error.error;
    },
    generateTournamentUrl(tournamentId) {
      return `/tournament/${tournamentId}`;
    },
  },
};
</script>

<style>
</style>
