// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import { createApp } from 'vue';
import App from './App.vue';
import router from './router';

/* ga('set', 'page', router.currentRoute.path); // eslint-disable-line
ga('send', 'pageview'); // eslint-disable-line */

ga(() => { // eslint-disable-line
  router.afterEach((to) => {
    ga('set', 'page', to.path); // eslint-disable-line
    ga('send', 'pageview'); // eslint-disable-line
  });
});

const app = createApp(App)
app.use(router)
app.mount('#app')
