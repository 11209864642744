<template>
  <div class="stats-tab">
    <div class="row">
      <div class="col-12">
        <p class="premade-type-container">
          <span class="h3">{{ seasonName }}</span>
          <span
            v-for="index in userCount"
            :key="index"
            class="fas fa-male fa-2x"
          />
          <span class="h3">{{ fpp }}</span>
        </p>
      </div>
    </div>
    <div class="row">
    <div
      v-for="property in properties"
      :key="property"
      class="col-md-6 col-12"
      >
      <StatsCard
          :icon="propertiesIcon[property]"
          :title="propertiesTitle[property]"
          :value="processValue(property, seasonStats[property])"
      />
    </div>
    </div>
  </div>
</template>

<script>
import StatsCard from '@/components/PlayerStats/StatsCard';

export default {
  name: 'StatsTab',
  components: {
    StatsCard,
  },
  props: [
    'properties',
    'propertiesIcon',
    'propertiesTitle',
    'seasonName',
    'premadeType',
    'seasonStats',
  ],
  data() {
    return {
      userCount: [],
      fpp: '',
    };
  },
  created() {
    const premadeUserCount = {
      solo: 1,
      'solo-fpp': 1,
      duo: 2,
      'duo-fpp': 2,
      squad: 4,
      'squad-fpp': 4,
    };

    this.userCount = premadeUserCount[this.premadeType] !== undefined
      ? Array.from(Array(premadeUserCount[this.premadeType]).keys()) : [];

    if (this.premadeType.indexOf('fpp') >= 0) {
      this.fpp = ' - FPP';
    }
  },
  methods: {
    getTotalDamage(damage) {
      return Math.round(damage);
    },
    getTotalWalkDistance(walkDistance) {
      return `${Math.round(walkDistance) / 1000} km`;
    },
    getTotalTimeSurvived(totalTimeSurvived) {
      return `${Math.trunc(totalTimeSurvived / 60)} minutes ${Math.round(totalTimeSurvived % 60)} seconds`;
    },
    processValue(key, value) {
      if (key === 'damageDealt') {
        return this.getTotalDamage(value);
      }
      if (key === 'walkDistance') {
        return this.getTotalWalkDistance(value);
      }
      if (key === 'timeSurvived') {
        return this.getTotalTimeSurvived(value);
      }

      return value;
    },
  },
};
</script>

<style>
  .premade-type-container {
    margin-top: 1rem;
    margin-bottom: 0;
  }
</style>
