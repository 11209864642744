<template>
  <div class="player-stats">
    <div class="card">
      <div class="card-body">
        <div v-if="loadedData">
          <div class="card-text">
            <div class="row">
              <div class="col-12">
                <h2 class="h2 text-center match-history-title">Match History</h2>
              </div>
            </div>
            <div class="match-history">
              <MatchHistoryCard
                v-for="(match, index) in matches"
                v-bind:key="match.id"
                v-bind:class="index > 4 && hideMatches ? 'd-none hiddenMatch' : ''"
                :matchId="match.id"
                :mapName="match.mapName"
                :gameMode="match.gameMode"
                :rosterPosition="match.rosterPosition"
                :totalRosters="match.totalRosters"
                :duration="`${Math.trunc(match.duration / 60)} minutes
                ${Math.round(match.duration % 60)} seconds`"
                :createdAt="match.createdAt"
                :playerName="match.randomParticipant.player.name"
                :playerId="match.randomParticipant.player.playerId"
                :platform="match.platform"
                :region="match.region"
              ></MatchHistoryCard>
              <!--<button
                v-if="matches.length > 4"
                v-on:click="showAll()"
                type="button"
                class="btn btn-info col-12 showAll"
              >
                Show all
              </button>-->
            </div>
          </div>
        </div>
        <div v-if="!loadedData" class="notLoadedMap">
          <div class="row">
            <div class="col-12">
              <div class="card">
                <div class="card-body">
                  <div class="text-center">
                    <span class="fas fa-spinner fa-spin"></span>
                    <p>
                      Loading newest <strong>{{ $route.params.tournamentId }}</strong>
                      tournament data. This operation may take a while. Please be patient 😀!
                    </p>
                    <p
                      v-if="errorMSG"
                      :class="errorMSG ? 'animated flash' : ''"
                      class="p-3 mb-2 bg-danger text-white"
                    >
                      {{ errorMSG }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MatchHistoryCard from '@/components/PlayerStats/MatchHistoryCard';

const axios = require('axios');

export default {
  name: 'PlayerStats',
  components: {
    MatchHistoryCard,
  },
  data() {
    return {
      loadedData: false,
      errorMSG: '',
      tournamentId: '',
      matches: [],
      hideMatches: false,
    };
  },
  async mounted() {
    this.tournamentId = this.$route.params.tournamentId;

    try {
      const result = await axios.get(`${this.$root.$data.api}/tournament/${this.tournamentId}`);
      if (result.data.success === true) {
        this.processSuccess(result.data.data);
      }
    } catch (error) {
      let errorData;
      if (error.response === undefined) {
        errorData = {
          error: 'Unknown error 😢. Please, try again later!',
        };
      } else {
        errorData = error.response.data;
      }

      this.processError(errorData);
      return;
    }

    document.title = `${this.tournamentId} .::. Tournament - PUBG Player Profile`;
    document.querySelector('meta[name="description"]')
      // eslint-disable-next-line
      .setAttribute('content', `${this.tournamentId} tournament matches.`);

    document.querySelector('meta[name="twitter:title"]')
      .setAttribute('content', `${this.tournamentId} .::. Tournament - PUBG Player Profile`);
    document.querySelector('meta[name="twitter:description"]')
      // eslint-disable-next-line
      .setAttribute('content', `${this.tournamentId} tournament matches.`);

    document.querySelector('meta[property="og:title"]')
      .setAttribute('content', `${this.tournamentId} .::. Tournament - PUBG Player Profile`);
    document.querySelector('meta[property="og:description"]')
      // eslint-disable-next-line
      .setAttribute('content', `${this.tournamentId} tournament matches.`);

    this.loadedData = true;
  },
  methods: {
    processSuccess(data) {
      this.matches = data.tournament.matches;
    },
    processError(error) {
      this.errorMSG = error.error;
    },
  },
};
</script>

<style scoped>
  .player-stats {
    margin-top: 20px;
  }
  .profile-card {
    font-size: 3em;
  }
  .match-history-title {
    margin-top: 20px;
  }
</style>
