import { createRouter, createWebHistory } from 'vue-router'
import LoginPage from '@/components/Home/LoginPage';
import PUBGMap from '@/components/PUBGMap/PUBGMap';
import PlayerStats from '@/components/PlayerStats/PlayerStats';
import TournamentMatchHistory from '@/components/PlayerStats/TournamentMatchHistory';

const router = createRouter({
  history: createWebHistory(),
  routes: [
    {
      path: '/',
      name: 'login',
      component: LoginPage,
    },
    {
      path: '/lastmatch/:platform/:region/:userName',
      name: 'lastmatch',
      component: PUBGMap,
    },
    {
      path: '/match/:platform/:region/:userName/:matchId/:playerId?',
      name: 'match',
      component: PUBGMap,
    },
    {
      path: '/profile/:platform/:region/:userName',
      name: 'profile',
      component: PlayerStats,
    },
    {
      path: '/tournament/:tournamentId',
      name: 'tournament',
      component: TournamentMatchHistory,
    },
  ],
});

export default router;
