<template>
  <div id="app" class="h-100">
    <main class="main">
      <div class="container">
        <div class="row">
          <div class="col-12">
            <div class="text-center">
              <router-link
                :to="{ path: `/` }"
              >
                <img class="img-fluid pubgprofile-logo" src="./assets/img/PUBGProfileLogo.png">
              </router-link>
            </div>
          </div>
        </div>
      </div>
      <div class="container">
        <router-view/>
      </div>
    </main>
    <PUBGFooter />
    <CookieFooter />
    <FooterModals />
  </div>
</template>

<script>
import PUBGFooter from '@/components/Home/PUBGFooter';
import CookieFooter from '@/components/Home/CookieFooter';
import FooterModals from '@/components/Home/FooterModals';

export default {
  name: 'App',
  data() {
    return {
      api: process.env.VUE_APP_API_URL,
      userName: '',
      platform: 'pc',
      region: 'na',
    }
  },
  components: {
    PUBGFooter,
    CookieFooter,
    FooterModals,
  },
};
</script>

<style>
  html {
    position: relative;
    min-height: 100%;
  }

  body {
    position: relative;
    height: 100%;
    background: url('./assets/img/background.jpg') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }

  .main {
    padding-bottom: 1.25em;
  }

  #app {
    position: relative;
    padding-bottom: 60px; /* Margin bottom by footer height */
  }

  .pubgprofile-logo {
    height: 200px;
  }
</style>
