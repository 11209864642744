<template>
  <div class="match-history-card">
    <div class="card bg-dark text-white">
      <div class="row">
        <div class="col-12">
          <div class="card-body">
            <p v-if="!duration"><span class="matchid"># {{ matchId }}</span></p>
            <div
              v-if="duration"
              class="w-100 match-history__card-container"
            >
              <ul class="list-unstyled">
                <li>
                  <span class="fas fa-map"></span>
                  {{ mapName }}
                </li>
                <li>
                  <span class="fas fa-cog"></span>
                  {{ gameMode }}
                </li>
                <li>
                  <span class="fas fa-stopwatch"></span>
                  {{ duration }}
                </li>
                <li>
                  <span class="fas fa-clock"></span>
                  {{ (new Date(createdAt)).toGMTString() }}
                </li>
              </ul>
              <span
                v-if="totalRosters"
                class="h3 match-history__position"
                :class="rosterPosition === 1 ? 'text-success' : 'text-warning'"
              >
                <strong>{{ rosterPosition }} / {{ totalRosters }}</strong>
              </span>
            </div>
          </div>
        </div>
        <div class="col-12">
          <router-link
            class="btn-warning
                   match-history-card-link
                   h-100
                   w-10
                   text-center
                   align-middle
                   align-items-center"
            :to="{ path: getMatchPath(playerName, playerId, matchId, platform, region) }"
          >
            <div>
              <span class="fas fa-arrow-right fa-3x"></span>
            </div>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'MatchHistoryCard',
  props: [
    'matchId',
    'mapName',
    'gameMode',
    'rosterPosition',
    'totalRosters',
    'duration',
    'createdAt',
    'playerName',
    'playerId',
    'platform',
    'region',
  ],
  data() {
    return {
    };
  },
  methods: {
    getMatchPath(playerName, playerId, matchId, platform, region) {
      const path = `/match/${platform}/${region}/${playerName}/${matchId}`;
      return playerId !== undefined ? `${path}/${playerId}` : path;
    },
  },
};
</script>

<style>
  .stats-card {
    margin-top: 20px;
  }
  .stats-card--value {
    font-weight: 900;
    font-size: 2em;
  }
  .stats-card--card-title {
    font-size: 1.33em;
  }
  .match-history-card-link {
    display: block;
  }
  .match-history-card-link--div {
    height: 100%;
    min-width: 100%;
  }
  .match-history__card-container {
    display: flex;
    justify-content: space-between;
  }
  .match-history__position {
    align-self: flex-end;
  }
</style>
