<template>
  <div class="player-list">
    <div class="list-group">
      <a
        v-for="participant in finalParticipantListStyled"
        :key="participant.name"
        class="list-group-item
               list-group-item-action
               d-flex
               justify-content-between
               align-items-center"
        :class="participant.listClass"
        :href="getMatchUrl(platform, region, participant.name, matchId, participant.accountId)"
        target="_blank"
      >
        {{ participant.name }}
        <span class="badge badge-primary badge-pill">{{ participant.ranking }}</span>
      </a>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PlayerList',
  props: [
    'finalParticipantList',
    'playerRanking',
    'platform',
    'region',
    'matchId',
    'playerId',
  ],
  data() {
    return {
      finalParticipantListStyled: [],
    };
  },
  methods: {
    getMatchUrl(platform, region, playerName, matchId, playerId) {
      const matchUrl = `/match/${platform}/${region}/${playerName}/${matchId}`;
      return this.playerId !== undefined ? `${matchUrl}/${playerId}` : matchUrl;
    },
  },
  created() {
    let rank = -1;
    const lightClass = 'list-group-item-light';
    const darkClass = 'list-group-item-dark';
    const successClass = 'list-group-item-success';
    let currentClass = darkClass;
    this.finalParticipantList.forEach((participant) => {
      const participantStyled = participant;

      if (participant.ranking === this.playerRanking) {
        participantStyled.listClass = successClass;
        this.finalParticipantListStyled.push(participantStyled);
        return;
      }

      if (participant.ranking !== rank) {
        rank = participant.ranking;
        if (currentClass === lightClass) {
          currentClass = darkClass;
        } else {
          currentClass = lightClass;
        }
      }

      participantStyled.listClass = currentClass;
      this.finalParticipantListStyled.push(participantStyled);
    });
  },
};
</script>

<style>
</style>
