<template>
  <div class="pubg-stats">
    <div class="card">
      <div class="card-header">
        <strong v-if="playerName && stats.name === playerName">You</strong>
        <span v-if="playerName && stats.name !== playerName">Teammate</span>
        <span v-if="!playerName">Winner</span>
      </div>
      <!--<div class="card-body">
        <h5 class="card-title">{{ stats.name }}</h5>
        <h6 class="card-subtitle mb-2 text-muted">Position: {{ stats.winPlace }}</h6>
        <p class="card-text">
          <ul>
            <li
              v-for="property in properties"
              v-bind:key="property"
            >
                <span
                  v-bind:class="propertiesIcon[property]"
                ></span>
                  <span>{{ propertiesTitle[property] }}</span>:
                <strong>{{ statsUpdated[property] }}</strong>
            </li>
          </ul>
        </p>
      </div>-->
      <div class="card-body">
        <div class="row">
          <div class="col-md-6 col-12 profile-card">
            <div class="card p-3 mb-2 bg-light text-dark">
              <div class="card-body">
                <div class="row">

                  <div class="col-lg-4">
                    <img
                      class="img-fluid"
                      src="../../assets/img/pubg-profile.jpg"
                      title="Profile"
                      alt="Profile"
                    >
                  </div>
                  <div class="col-lg-8">
                    <span
                      v-if="playerName && stats.name === playerName"
                      class="align-middle"
                    > {{ stats.name }}</span>
                    <a
                      v-else
                      :href="getMatchUrl(stats.name, stats.playerId)"
                      target="_blank"
                    >{{ stats.name }} <span class="fas fa-external-link-alt fa-xs"></span></a>
                    <p v-if="playerName && stats.name === playerName">
                      <button
                        id="displayGameLog"
                        class="btn btn-secondary col-12"
                        type="button"
                        data-toggle="modal"
                        data-target="#gameLogModal"
                      >
                        See stats
                      </button>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            v-for="property in properties"
            :key="property"
            class="col-md-6 col-12"
          >
            <StatsCard
              :icon="propertiesIcon[property]"
              :title="propertiesTitle[property]"
              :value="statsUpdated[property]"
            >
            </StatsCard>
          </div>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import StatsCard from '@/components/PlayerStats/StatsCard';

export default {
  name: 'PUBGStats',
  components: {
    StatsCard,
  },
  data() {
    return {
      properties: [
        'kills',
        'headshotKills',
        'DBNOs',
        'damageDealt',
        'longestKill',
        'timeSurvived',
        'walkDistance',
        'rideDistance',
      ],
      propertiesTitle: {
        kills: 'Kills',
        headshotKills: 'Headshots',
        DBNOs: 'DBNOs',
        damageDealt: 'Damage Dealt',
        longestKill: 'Max. Kill Distance',
        timeSurvived: 'Time Survived',
        walkDistance: 'Walk Distance',
        rideDistance: 'Ride Distance',
      },
      propertiesIcon: {
        kills: 'fas fa-times-circle',
        headshotKills: 'fas fa-car',
        DBNOs: 'fas fa-bed',
        damageDealt: 'fas fa-crosshairs',
        longestKill: 'fas fa-compress',
        timeSurvived: 'fas fa-stopwatch',
        walkDistance: 'fas fa-map-marker',
        rideDistance: 'fas fa-car',
      },
      statsUpdated: {}
    };
  },
  props: {
    stats: {
      type: Object,
      required: true,
    },
    playerName: {
      type: String,
      required: false,
    },
    playerId: {
      type: String,
      required: true,
    },
  },
  mounted() {
    this.statsUpdated = { ...this.stats }
    this.statsUpdated.damageDealt = Math.round(this.stats.damageDealt);
    this.statsUpdated.walkDistance = `${Math.round(this.stats.walkDistance) / 1000} km`;
    this.statsUpdated.rideDistance = `${Math.round(this.stats.rideDistance) / 1000} km`;
    this.statsUpdated.longestKill = `${Math.round(this.stats.longestKill)} m`;
    this.statsUpdated.timeSurvived = `${Math.trunc(this.stats.timeSurvived / 60)} minutes ${Math.round(this.stats.timeSurvived % 60)} seconds`;
  },
  methods: {
    getMatchUrl(playerName, playerId) {
      const matchUrl = `/match/${this.$root.platform}/${this.$root.region}/${playerName}/${this.$route.params.matchId}`;
      return this.playerId !== undefined ? `${matchUrl}/${playerId}` : matchUrl;
    },
  },
};
</script>

<style scoped>
  .pubg-stats {
      margin-bottom: 20px;
  }
  .profile-card {
    font-size: 3em;
  }
  .match-history-title {
    margin-top: 20px;
  }
</style>
