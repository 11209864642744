<template>
  <div class="player-kill">
    <div
      class="modal fade"
      id="playerKillModal"
      tabindex="-1"
      role="dialog"
      aria-labelledby="playerKillModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="playerKillModalLabel">Player Killed</h5>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <div
                v-if="playerKill"
              >
                <p>
                  <strong v-if="playerKill.killer.name !== playerName">
                    <a
                      v-if="playerKill.killer.name !== ''"
                      :href="getMatchUrl($root.$data.platform,
                                         $root.$data.region,
                                         playerKill.killer.name,
                                         matchId,
                                         playerKill.killer.accountId)"
                      target="_blank"
                    >
                      {{ playerKill.killer.name }}</a>
                    <span v-else>
                      {{ playerKill.killer.name }}
                    </span>
                  </strong>
                  <strong v-else class="text-success">YOU</strong>
                    killed
                  <strong v-if="playerKill.victim.name !== playerName">
                    <a
                      :href="getMatchUrl($root.$data.platform,
                                         $root.$data.region,
                                         playerKill.victim.name,
                                         matchId,
                                         playerKill.victim.accountId)"
                      target="_blank"
                    >
                      {{ playerKill.victim.name }}</a>
                  </strong>
                  <strong v-else class="text-danger">YOU</strong>
                    with
                  {{ getDamageCauserName() }}
                </p>
                <p>
                  <span class="fas fa-arrows-alt-h"></span> {{ getDistance() }} metres
                </p>
              </div>
              <div
                v-else
              >
                <strong class="text-warning">Winner, Winner Chicken Dinner</strong>
              </div>
              <p
                v-if="location"
              >
                <span class="fas fa-stopwatch"></span> {{ getElapsedTime() }}
              </p>
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-primary" data-dismiss="modal">Close</button>
            </div>
          </div>
      </div>
    </div>
  </div>
</template>

<script>
import DamageCauserName from '../../../assets/json/damagecausername';

export default {
  name: 'PlayerKill',
  props: [
    'playerKill',
    'location',
    'playerName',
    'matchId',
    'playerId',
  ],
  data() {
    return {
    };
  },
  methods: {
    getDamageCauserName() {
      return DamageCauserName[this.playerKill.damageCauserName] !== undefined ?
        DamageCauserName[this.playerKill.damageCauserName] : this.playerKill.damageCauserName;
    },
    getElapsedTime() {
      return `${Math.trunc(this.location.elapsedTime / 60)} minutes ${this.location.elapsedTime % 60} seconds`;
    },
    getDistance() {
      return Math.round(this.playerKill.distance / 100);
    },
    getMatchUrl(platform, region, playerName, matchId, playerId) {
      const matchUrl = `/match/${platform}/${region}/${playerName}/${matchId}`;
      return this.playerId !== undefined ? `${matchUrl}/${playerId}` : matchUrl;
    },
  },
  created() {
  },
};
</script>

<style>
</style>
