<template>
  <div class="player-stats">
    <div class="card">
      <div class="card-body">
        <div v-if="loadedData">
          <div class="card-text">
            <div class="row">
              <div class="col-12 profile-card">
                <div class="card p-3 mb-2 bg-light text-dark">
                  <div class="card-body">
                    <div class="row">
                      <div class="col-md-4">
                        <img
                          class="img-fluid"
                          src="../../assets/img/pubg-profile.jpg"
                          title="Profile"
                          alt="Profile"
                        >
                      </div>
                      <div class="col-md-8">
                        <span class="align-middle"> {{ playerName }}
                          <button
                            id="refreshMatchButton"
                            type="button"
                            class="btn btn-info"
                            v-on:click="refreshMatchHistory"
                          >
                            <span id="refreshMatchButtonSpan" class="fas fa-sync fa-2x"></span>
                          </button>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-12">
                <!-- PlayerProfile -->
                <ins class="adsbygoogle"
                    style="display:block"
                    :data-ad-client="`ca-${playerProfileAds.displayUnderPlayer.publisherId}`"
                    :data-ad-slot="playerProfileAds.displayUnderPlayer.slot"
                    data-ad-format="auto"
                    data-full-width-responsive="true"></ins>
              </div>
              <div class="col-12 season-container">
                <ul class="nav nav-tabs" role="tablist">
                  <li class="nav-item">
                    <a
                      id="fullStats"
                      class="nav-link active"
                      data-toogle="tab"
                      href="#fullStatsTab"
                      role="tab"
                      aria-controls="fullStatsTab"
                      aria-selected="true"
                    >
                      Lifetime Stats
                    </a>
                  </li>
                  <li
                    v-for="seasonId in Object.keys(seasonStats.stats)"
                    :key="seasonId"
                  >
                    <a
                      class="nav-link dropdown-toggle"
                      data-toggle="dropdown"
                      href="#"
                      role="button"
                      aria-haspopup="true"
                      aria-expanded="false">
                      {{ seasonStats.stats[seasonId].seasonName }}
                    </a>
                    <div class="dropdown-menu">
                      <a
                        v-for="premadeType in Object.keys(seasonStats.stats[seasonId].stats)"
                        :key="premadeType"
                        :id="`${seasonId}-${premadeType}`"
                        class="dropdown-item"
                        data-toogle="tab"
                        :href="`#${seasonId}-${premadeType}-Tab`"
                        role="tab"
                        :aria-controls="`${seasonId}-${premadeType}-Tab`"
                        aria-selected="false"
                      >
                      {{ getPremadeType(premadeType) }}
                      </a>
                    </div>
                  </li>
                </ul>
                <div class="tab-content">
                  <div
                    id="fullStatsTab"
                    class="tab-pane fade show active"
                    aria-labelledby="fullStats"
                  >
                    <StatsTab
                      :properties="properties"
                      :propertiesIcon="propertiesIcon"
                      :propertiesTitle="propertiesTitle"
                      :seasonName="'Lifetime Stats'"
                      :premadeType="'none'"
                      :seasonStats="seasonStats.aggregations"
                    />
                  </div>
                  <template
                    v-for="seasonId in Object.keys(seasonStats.stats)"
                  >
                    <div
                      v-for="premadeType in Object.keys(seasonStats.stats[seasonId].stats)"
                      :key="`${seasonId}-${premadeType}`"
                      :id="`${seasonId}-${premadeType}-Tab`"
                      class="tab-pane fade"
                      :aria-labelledby="`${seasonId}-${premadeType}`"
                    >
                      <StatsTab
                        :properties="properties"
                        :propertiesIcon="propertiesIcon"
                        :propertiesTitle="propertiesTitle"
                        :seasonName="`${seasonStats.stats[seasonId].seasonName} - `"
                        :premadeType="premadeType"
                        :seasonStats="seasonStats.stats[seasonId].stats[premadeType]"
                      />
                    </div>
                  </template>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <h2 class="h2 text-center match-history-title">Match History</h2>
              </div>
            </div>
            <div class="match-history">
              <ins class="adsbygoogle"
                style="display:block"
                data-ad-format="fluid"
                :data-ad-layout-key="playerProfileAds.displayInMatchList.layoutKey"
                :data-ad-client="`ca-${playerProfileAds.displayInMatchList.publisherId}`"
                :data-ad-slot="playerProfileAds.displayInMatchList.slot"></ins>
              <MatchHistoryCard
                v-for="(match) in matchesShow"
                v-bind:key="match.id"
                class="animated fadeIn"
                :matchId="match.id"
                :mapName="match.mapName"
                :gameMode="match.gameMode"
                :rosterPosition="match.rosterPosition"
                :totalRosters="match.totalRosters"
                :duration="match.duration"
                :createdAt="match.createdAt"
                :playerName="playerName"
                :platform="platform"
                :region="region"
              ></MatchHistoryCard>
              <div class="row">
                <div class="col-6 showAllContainer showAllContainer--left">
                  <button
                    v-if="matchesShow.length !== matches.length"
                    v-on:click="showMore()"
                    type="button"
                    class="btn btn-info col-12 showAll"
                  >
                    Show more
                  </button>
                </div>
                <div class="col-6 showAllContainer showAllContainer--right">
                  <button
                    v-if="matchesShow.length !== matches.length"
                    v-on:click="showAll()"
                    type="button"
                    class="btn btn-secondary col-12 showAll"
                  >
                    Show all
                  </button>
                </div>
               </div>
            </div>
          </div>
        </div>
        <div v-if="!loadedData" class="notLoadedMap">
          <div class="row">
            <div class="col-12">
              <div class="card">
                <div class="card-body">
                  <div class="text-center">
                    <span class="fas fa-spinner fa-spin"></span>
                    <p>
                      Loading newest <strong>{{ $route.params.userName }}</strong> profile data.
                      This operation may take a while. Please be patient 😀!
                    </p>
                    <p
                      v-if="errorMSG"
                      :class="errorMSG ? 'animated flash' : ''"
                      class="p-3 mb-2 bg-danger text-white"
                    >
                      {{ errorMSG }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import StatsTab from '@/components/PlayerStats/StatsTab';
import MatchHistoryCard from '@/components/PlayerStats/MatchHistoryCard';

const axios = require('axios');

export default {
  name: 'PlayerStats',
  components: {
    StatsTab,
    MatchHistoryCard,
  },
  data() {
    return {
      loadedData: false,
      errorMSG: '',
      properties: [
        'kills',
        'wins',
        'headshotKills',
        'walkDistance',
        'damageDealt',
        'timeSurvived',
        'roundsPlayed',
      ],
      propertiesTitle: {
        kills: 'Kills',
        wins: 'Wins',
        headshotKills: 'Headshots',
        walkDistance: 'Walk Distance',
        damageDealt: 'Damage Dealt',
        timeSurvived: 'Time Survived',
        roundsPlayed: 'Total processed matches',
      },
      propertiesIcon: {
        kills: 'fas fa-times-circle',
        wins: 'fas fa-check',
        headshotKills: 'Headshots',
        walkDistance: 'fas fa-map-marker',
        damageDealt: 'fas fa-crosshairs',
        timeSurvived: 'fas fa-stopwatch',
        roundsPlayed: 'fas fa-server',
      },
      globalStats: this.$root.$data.globalStats,
      seasonStats: this.$root.$data.seasonStats,
      matchesInfo: this.$root.$data.matchesInfo,
      player: this.$root.$data.player,
      playerName: this.$root.$data.userName,
      platform: this.$root.$data.platform,
      region: this.$root.$data.region,
      hideMatches: true,

      playerProfileAds: {
        displayUnderPlayer: {
          publisherId: "pub-8650061678387324",
          slot: "5982895822",
        },
        displayInMatchList: {
          publisherId: "pub-8650061678387324",
          slot: "1620978985",
          layoutKey: "-gp-d+1d-4q+8k",
        },
      },
      matchesShow: [],
    };
  },
  async mounted() {
    const $ = window.$;

    if (this.$root.$data.userName !== this.$route.params.userName) {
      this.userName = this.$route.params.userName;
      this.platform = this.$route.params.platform;
      this.region = this.$route.params.region;

      try {
        const result = await axios.get(`${this.$root.$data.api}/player/profile/${this.platform}/${this.region}/${this.userName}`);
        if (result.data.success === true) {
          this.processSuccess(result.data.data);
        }
      } catch (error) {
        let errorData;
        if (error.response === undefined) {
          errorData = {
            error: 'Unknown error 😢. Please, try again later!',
          };
        } else {
          errorData = error.response.data;
        }

        this.processError(errorData);
        return;
      }
    } else {
      this.$root.$data.seasonStats = this.filterSeasonStats(this.seasonStats);
      this.seasonStats = this.$root.$data.seasonStats;
    }

    document.title = `${this.$root.$data.userName} .::. Profile - PUBG Player Profile`;
    document.querySelector('meta[name="description"]')
      // eslint-disable-next-line
      .setAttribute('content', `${this.$root.$data.userName} - ${this.$root.$data.platform.toUpperCase()} profile stats, including kills or rounds played among others.`);

    document.querySelector('meta[name="twitter:title"]')
      .setAttribute('content', `${this.$root.$data.userName} .::. Profile - PUBG Player Profile`);
    document.querySelector('meta[name="twitter:description"]')
      // eslint-disable-next-line
      .setAttribute('content', `${this.$root.$data.userName} - ${this.$root.$data.platform.toUpperCase()} profile stats, including kills or rounds played among others.`);

    document.querySelector('meta[property="og:title"]')
      .setAttribute('content', `${this.$root.$data.userName} .::. Profile - PUBG Player Profile`);
    document.querySelector('meta[property="og:description"]')
      // eslint-disable-next-line
      .setAttribute('content', `${this.$root.$data.userName} - ${this.$root.$data.platform.toUpperCase()} profile stats, including kills or rounds played among others.`);

    this.matches = [];
    this.player.matches.forEach((playerMatch) => {
      const match = playerMatch;
      // eslint-disable-next-line
      for (const matchInfo of this.matchesInfo) {
        if (matchInfo.id === match.id) {
          match.mapName = matchInfo.mapName.charAt(0).toUpperCase() + matchInfo.mapName.slice(1);
          match.gameMode = matchInfo.gameMode;
          match.createdAt = matchInfo.createdAt;
          match.rosterPosition = matchInfo.stats.roster.rank;
          match.totalRosters = matchInfo.totalRosters;
          match.duration = `${Math.trunc(matchInfo.duration / 60)} minutes ${Math.round(matchInfo.duration % 60)} seconds`;
          break;
        }
      }

      this.matches.push(match);
    });

    this.showMore();

    this.loadedData = true;

    $(() => {
      $('#app').on('click', '.nav-link, .dropdown-item', function showBSTab(e) {
        e.preventDefault();
        $('.dropdown-item').removeClass('active show');
        $(this).tab('show');
      });
    });

    setTimeout(() => {
        (adsbygoogle = window.adsbygoogle || []).push({});
        (adsbygoogle = window.adsbygoogle || []).push({});
      }, 200);
  },
  methods: {
    processSuccess(data) {
      this.$root.$data.globalStats = data.globalStats[0];
      this.$root.$data.seasonStats = this.filterSeasonStats(data.seasonStats);
      this.$root.$data.matchesInfo = data.matchesInfo;
      this.$root.$data.player = data.player;

      this.$root.$data.userName = this.userName;
      this.$root.$data.platform = this.platform;
      this.$root.$data.region = this.region;

      this.globalStats = data.globalStats[0];
      this.seasonStats = this.$root.$data.seasonStats;
      this.matchesInfo = data.matchesInfo;
      this.player = data.player;

      this.playerName = this.userName;
    },
    processError(error) {
      this.errorMSG = error.error;
    },
    showAll() {
      this.matchesShow = this.matches;
    },
    showMore() {
      for ( let i = this.matchesShow.length, currentLength = this.matchesShow.length, length = this.matches.length;
            i < length && i < currentLength + 10;
            i++) {
        this.matchesShow.push(this.matches[i]);
      }
    },
    filterSeasonStats(seasonStatsParam) {
      // Only display seasons whose info is available
      const keys = Object.keys(seasonStatsParam.stats);
      const seasonList = {};
      const seasonStats = seasonStatsParam;
      let seasonCount = 0;
      keys.forEach((key) => {
        seasonCount += 1;

        const premadeTypeKeys = Object.keys(seasonStatsParam.stats[key].stats);
        let playedAGameThisSeason = false;
        premadeTypeKeys.forEach((premadeTypeKey) => {
          if (seasonStatsParam.stats[key].stats[premadeTypeKey].roundsPlayed !== 0) {
            playedAGameThisSeason = true;
          }
        });

        if (playedAGameThisSeason) {
          const sanitizedKey = key.replace(/\./g, '');
          seasonList[sanitizedKey] = seasonStatsParam.stats[key];
          seasonList[sanitizedKey].seasonName = `Season ${seasonCount}`;
        }
      });
      seasonStats.stats = seasonList;

      return seasonStats;
    },
    getPremadeType(premadeType) {
      const premadeNames = {
        duo: "Duo TPP",
        "duo-fpp": "Duo FPP",
        solo: "Solo TPP",
        "solo-fpp": "Solo FPP",
        squad: "Squad TPP",
        "squad-fpp": "Squad FPP",
        "conquest-duo": "Conquest Duo TPP",
        "conquest-duo-fpp": "Conquest Duo FPP",
        "conquest-solo": "Conquest Solo TPP",
        "conquest-solo-fpp": "Conquest Solo FPP",
        "conquest-squad": "Conquest Squad TPP",
        "conquest-squad-fpp": "Conquest Squad FPP",
        "esports-duo": "Esports Duo TPP",
        "esports-duo-fpp": "Esports Duo FPP",
        "esports-solo": "Esports Solo TPP",
        "esports-solo-fpp": "Esports Solo FPP",
        "esports-squad": "Esports Squad TPP",
        "esports-squad-fpp": "Esports Squad FPP",
        "normal-duo": "Duo TPP",
        "normal-duo-fpp": "Duo FPP",
        "normal-solo": "Solo TPP",
        "normal-solo-fpp": "Solo FPP",
        "normal-squad": "Squad TPP",
        "normal-squad-fpp": "Squad FPP",
        "war-duo": "War Duo TPP",
        "war-duo-fpp": "War Duo FPP",
        "war-solo": "War Solo TPP",
        "war-solo-fpp": "War Solo FPP",
        "war-squad": "Squad TPP",
        "war-squad-fpp": "War Squad FPP",
        "zombie-duo": "Zombie Duo TPP",
        "zombie-duo-fpp":"Zombie Duo FPP",
        "zombie-solo": "Zombie Solo TPP",
        "zombie-solo-fpp": "Zombie Solo FPP",
        "zombie-squad": "Zombie Squad TPP",
        "zombie-squad-fpp": "Zombie Squad FPP",
        "lab-tpp": "Lab TPP",
        "lab-fpp": "Lab FPP",
        tdm: "Team Deathmatch",
      };
      return premadeNames[premadeType];
    },
    async refreshMatchHistory() {
      const button = document.getElementById('refreshMatchButton');
      button.disabled = true;

      const span = document.getElementById('refreshMatchButtonSpan');
      span.classList = 'fas fa-circle-notch fa-spin fa-2x';

      try {
        const result = await axios.get(`${this.$root.$data.api}/game/all/${this.platform}/${this.region}/${this.playerName}`);
        if (result.data.success === true) {
          setTimeout(() => {
            span.classList = 'fas fa-sync fa-2x';
            window.location.reload(false);
          }, 1000);
        } else {
          span.classList = 'fas fa-exclamation-triangle fa-2x';
        }
        button.disabled = false;
      } catch (error) {
        span.classList = 'fas fa-exclamation-triangle fa-2x';
      }
    },
  },
};
</script>

<style scoped>
  .player-stats {
    margin-top: 20px;
  }
  .profile-card {
    font-size: 3em;
  }
  .match-history-title {
    margin-top: 20px;
  }
  .showAllContainer {
    margin-top: 1em;
    margin-bottom: 1em;
  }
  .showAllContainer--left {
    padding-right: 1em;
  }
  .showAllContainer--right {
    padding-left: 1em;
  }
</style>
