<template>
  <div class="game-log">
    <div
      class="modal fade"
      id="gameLogModal"
      tabindex="-1"
      role="dialog"
      aria-labelledby="gameLogModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="gameLogModalLabel">{{ playerName }} Game Log</h5>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <div class="table-responsive">
                <table class="table table-striped table-bordered table-dark">
                  <thead>
                    <th>Event</th>
                    <th>Health</th>
                    <th>Weapon</th>
                    <th>More info.</th>
                  </thead>
                  <tbody>
                    <tr
                      v-for="attack in orderedEvents"
                      :key="`${attack._D}-${attack.attackId}`"
                    >
                      <td>
                        <span v-if="attack.attacker">
                          <strong>
                            <a
                              v-if="attack.attacker.name
                                      &&
                                    attack.attacker.name !== playerName"
                              :href="getMatchUrl(attack.attacker.name, attack.victim.accountId)"
                              class="text-danger"
                              target="_blank"
                            >{{ attack.attacker.name }}
                              <span class="fas fa-external-link-alt fa-xs"></span></a>
                            <span v-else class="text-success">
                              <span v-if="attack.attacker.name">
                                {{ attack.attacker.name }}
                              </span>
                              <span v-else>
                                Unknown
                              </span>
                            </span>
                          </strong> shooted
                          <strong>
                            <a
                              v-if="attack.victim.name !== playerName"
                              :href="getMatchUrl(attack.victim.name, attack.victim.accountId)"
                              class="text-danger"
                              target="_blank"
                            >{{ attack.victim.name }}
                              <span class="fas fa-external-link-alt fa-xs"></span></a>
                            <span v-else class="text-success">
                              {{ attack.victim.name }}
                            </span>
                          </strong>
                        </span>
                        <span v-else>
                          <strong
                            :class="attack.victim.name === playerName
                              ? 'text-success' : 'text-danger'"
                          >
                            {{ attack.victim.name }}
                          </strong> received damage
                        </span>
                      </td>
                      <td>{{ getDamage(attack.damage) }}</td>
                      <td>{{ getDamageCauserName(attack.damageCauserName) }}</td>
                      <td>
                        {{ getDamageCategory(attack.damageTypeCategory) }}
                        <span v-if="getDamageReason(attack.damageReason)">
                          - {{ getDamageReason(attack.damageReason) }}
                        </span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-primary" data-dismiss="modal">Close</button>
            </div>
          </div>
      </div>
    </div>
  </div>
</template>

<script>
import DamageCauserName from '../../../assets/json/damagecausername';
import DamageReason from '../../../assets/json/damagereason';
import DamageCategory from '../../../assets/json/damagecategory';

export default {
  name: 'GameLog',
  props: [
    'gameLog',
    'playerName',
  ],
  data() {
    return {
      playerAttack: [],
      playerTakeDamage: [],
      orderedEvents: [],
    };
  },
  methods: {
    getDamage(damage) {
      return Math.round(damage);
    },
    getDamageCauserName(damageCauserName) {
      return DamageCauserName[damageCauserName] !== undefined ?
        DamageCauserName[damageCauserName] : damageCauserName;
    },
    getDamageReason(damageReason) {
      return DamageReason[damageReason] !== undefined ?
        DamageReason[damageReason] : damageReason;
    },
    getDamageCategory(damageCategory) {
      return DamageCategory[damageCategory] !== undefined ?
        DamageCategory[damageCategory] : damageCategory;
    },
    getElapsedTime() {
      return `${Math.trunc(this.location.elapsedTime / 60)} minutes ${this.location.elapsedTime % 60} seconds`;
    },
    getMatchUrl(playerName, playerId) {
      const matchUrl = `/match/${this.$root.platform}/${this.$root.region}/${playerName}/${this.$route.params.matchId}`;
      return this.playerId !== undefined ? `${matchUrl}/${playerId}` : matchUrl;
    },
  },
  watch: {
    gameLog(gameLog) {
      this.playerAttack = gameLog.playerAttack;
      this.playerTakeDamage = gameLog.playerTakeDamage;

      const playerAttackLength = this.playerAttack.length;
      const playerTakeDamageLength = this.playerTakeDamage.length;
      const length = playerAttackLength + playerTakeDamageLength;
      for (let i = 0, a = 0, t = 0; i < length; i += 1) {
        // eslint-disable-next-line
        const attackDate = a === playerAttackLength ? new Date() : new Date(this.playerAttack[a]._D);
        // eslint-disable-next-line
        const takeDamageDate = t === playerTakeDamageLength ? new Date() : new Date(this.playerTakeDamage[t]._D);

        if (attackDate < takeDamageDate) {
          this.orderedEvents.push(this.playerAttack[a]);
          a += 1;
        } else {
          this.orderedEvents.push(this.playerTakeDamage[t]);
          t += 1;
        }
      }
    },
  },
};
</script>

<style>
</style>
