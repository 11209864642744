<template>
  <div id="cookieFooterContainer" v-if='showCookies' class='cookie-footer'>
    <div class='container'>
      <span class='text-white'>
        This website uses cookies to improve user experience.
        By using our website you consent to all cookies in accordance with our Cookie Policy.
      </span>
      <a
        id='cookiesPolicyLink'
        href='#'
        data-toggle='modal'
        data-target='#cookiesPolicy'
        onclick="ga('send', 'event', 'CookieFooter', 'click', 'cookiesPolicy')"
      >
        Read more</a>
      <div class="float-right">
          <a
          class='btn btn-secondary'
          title='Disagree'
          href='https://google.com'
          onclick="ga('send', 'event', 'CookieFooter', 'click', 'reject')"
        >
          I disagree
        </a>
        <button
          v-on:click='acceptedPolicy'
          class='btn btn-success'
        >
          I agree
        </button>
      </div>
      <div class="clearfix"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CookieFooter',
  data() {
    return {
      showCookies: false,
    };
  },
  created() {
    if (this.getCookie('cookiePolicy') !== 'true') {
      this.showCookies = true;
    }
  },
  methods: {
    setCookie(name, value, days) {
      const d = new Date();
      d.setTime(d.getTime() + (24 * 60 * 60 * 1000 * days));
      const expires = `; expires=${d.toGMTString()}`;
      document.cookie = `${name}=${value}${expires}; path=/`;
    },
    getCookie(name) {
      const v = document.cookie.match(`(^|;) ?${name}=([^;]*)(;|$)`);
      return v ? v[2] : null;
    },
    acceptedPolicy() {
      this.setCookie('cookiePolicy', 'true', 365);
      const cookieContainer = document.getElementById('cookieFooterContainer');
      cookieContainer.parentNode.removeChild(cookieContainer);
      ga('send', 'event', 'CookieFooter', 'click', 'accept'); // eslint-disable-line
    },
  },
};
</script>

<style>
  .cookie-footer {
    position: absolute;
    top: 0px;
    width: 100%;
    background-color: #000;
    padding-top: 1em;
    padding-bottom: 1em;
  }
</style>
