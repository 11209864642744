<template>
  <footer class="footer">
    <div class="container">
      <ul class="list-inline list-inline__custom">
        <li class="list-inline-item">
            <a
              id="aboutMeLink"
              href="#"
              data-toggle="modal"
              data-target="#aboutMe"
              onclick="ga('send', 'event', 'PUBGFooter', 'click', 'aboutMe')"
            >
              About me
            </a>
        </li>
        <li class="list-inline-item">
            <a
              id="contactLink"
              href="#"
              data-toggle="modal"
              data-target="#contact"
              onclick="ga('send', 'event', 'PUBGFooter', 'click', 'contact')"
            >
              Contact
            </a>
        </li>
        <li class="list-inline-item">
            <a
              id="privacyPolicyLink"
              href="#"
              data-toggle="modal"
              data-target="#privacyPolicy"
              onclick="ga('send', 'event', 'PUBGFooter', 'click', 'privacyPolicy')"
            >
              Privacy Policy
            </a>
        </li>
        <li class="list-inline-item">
            <a
              id="cookiesPolicyLink"
              href="#"
              data-toggle="modal"
              data-target="#cookiesPolicy"
              onclick="ga('send', 'event', 'PUBGFooter', 'click', 'cookiesPolicy')"
            >
              Cookies Policy
            </a>
        </li>
      </ul>
    </div>
  </footer>
</template>

<script>
export default {
  name: 'PUBGFooter',
};
</script>

<style>
  .footer {
    position: absolute;
    bottom: 0px;
    width: 100%;
    height: 60px; /* Set the fixed height of the footer here */
    line-height: 60px; /* Vertically center the text there */
    background-color: #000;
    overflow: hidden;
  }

  .list-inline__custom {
    -webkit-margin-after: 0;
    padding-left: 40px;
  }
</style>
